import React from 'react'
import { PageProps } from 'gatsby'
import { Head, Breadcrumb, Footer } from '~/components'
import { useApi } from '~/siteApi'
import * as st from '~/assets/styl/PrivacyPolicy.module.styl'
import * as st1 from '~/assets/styl/Hero.module.styl'

interface Context {
  name: string
  title: string
  text: string
  slug: string
}

const PrivacyPolicy = ({
  location,
  pageContext,
}: PageProps & { pageContext: Context }) => {
  const { result: context }: { result: Context } = useApi(
    { result: pageContext },
    'pages/' + pageContext.slug
  )

  return (
    <>
      <Head
        location={location}
        title={context.name + ' - ' + process.env.GATSBY_SITE_NAME}
      />
      <header className={st.header}>
        <Breadcrumb />
        <span className={st1.pretitle}>Holder Construtora e Incorporadora</span>
        <h1 className={st1.title}>{context.title}</h1>
      </header>
      <div
        className={st.content}
        dangerouslySetInnerHTML={{ __html: context.text }}
      ></div>
      <Footer />
    </>
  )
}

export default PrivacyPolicy
